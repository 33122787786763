import { on } from './utils/dom';
import { pushToDl } from './parts/tracking';

function openPopup(url: string, width: number, height:number) {
    let top = window.screen.height - height;
    let left = window.screen.width - width;

    top = top > 0 ? top / 2 : 0;
    left = left > 0 ? left / 2 : 0;

    window.open(url, '_blank', `width=${width},height=${height},top=${top},left=${left}`);
}

on('click', '[data-js-links-bar-item]', (event, el) => {
    const id = el.dataset.jsLinksBarItem ?? '';
    const socialNetworkName = el.getAttribute('title');
    const url = window['links-bar']?.[id];

    if (url) {
        openPopup(url, 540, 440);
    }

    if (socialNetworkName) {
        pushToDl({
            event: 'social_share',
            eventCategory: 'social',
            eventAction: 'shared',
            eventLabel: socialNetworkName,
        });
    }
});
